import OrdersPageComponent from "./components/OrdersPageComponent";

import axios from "axios";

const getOrders = async() => {
    const { data } = await axios.get("/api/orders/admin");
    return data
}

const fetchOrders = async (abctrl) => {
  const {data} = await axios.get("/api/orders/admin", {
      signal: abctrl.signal,
  });
  return data
}

const deleteOrder = async (userId) => {
  const { data } = await axios.delete(`/api/orders/${userId}`);
  return data
}

const AdminOrdersPage = () => {
  return <OrdersPageComponent fetchOrders={fetchOrders} deleteOrder={deleteOrder} />
};

export default AdminOrdersPage;
