import AdminCouponsPageComponent from "./components/AdminCouponsPageComponent";

import axios from "axios";

const fetchCoupons = async (abctrl) => {
    const {data} = await axios.get("/api/coupons", {
        signal: abctrl.signal,
    });
    return data
}

const deleteCoupon = async (userId) => {
    const { data } = await axios.delete(`/api/coupons/${userId}`);
    return data
}

const AdminCouponsPage = () => {
  return <AdminCouponsPageComponent fetchCoupons={fetchCoupons} deleteCoupon={deleteCoupon} />;
};

export default AdminCouponsPage;