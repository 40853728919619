import {
  Row,
  Col,
  Container,
  Image,
  ListGroup,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import AddedToCartMessageComponent from "../../components/AddedToCartMessageComponent";
import ImageZoom from "js-image-zoom";
import { useEffect, useState, useRef } from "react";
import MetaComponent from "../../components/MetaComponent";
import { useParams } from "react-router-dom";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { LinkContainer } from "react-router-bootstrap";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NumberFormat from "react-number-format";
import CurrencyInput from 'react-currency-input-field';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Star from '@mui/icons-material/Star';

const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')

const ProductDetailsPageComponent = ({
  addToCartReduxAction,
  reduxDispatch,
  getProductDetails,
  userInfo,
  writeReviewApiRequest
}) => {
  const { id } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(1);
  
  const [showCartMessage, setShowCartMessage] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productReviewed, setProductReviewed] = useState(false);

  const [showProductReviewed, setShowProductReviewed] = useState(false);
  const messagesEndRef = useRef(null);

  const addToCartHandler = () => {
    reduxDispatch(addToCartReduxAction(id, quantity));
    setShowCartMessage(true);
  };

  useEffect(() => {
    if (productReviewed) {
        setTimeout(() => {
             messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }, 200)
    }  
  }, [productReviewed])

  useEffect(() => {
    if (product.images) {
      var options = {
        // width: 400,
        // zoomWidth: 500,
        // fillContainer: true,
        // zoomPosition: "bottom",
        scale: 2,
        offset: { vertical: 0, horizontal: 0 },
      };

      product.images.map(
        (image, id) =>
          new ImageZoom(document.getElementById(`imageId${id + 1}`), options)
      );
    }
  });

  useEffect(() => {
    getProductDetails(id)
      .then((data) => {
        setProduct(data);
        setLoading(false);
        setRating("");
        setQuantity(1);
        setComment("");
      })
      .catch((er) =>
        setError(
          er.response.data.message ? er.response.data.message : er.response.data
        )
      );
  }, [id, productReviewed]);

  const sendReviewHandler = (e) => {
     e.preventDefault();
     const form = e.currentTarget.elements;
     const formInputs = {
         comment: comment,
         rating: rating,
     }
     if (e.currentTarget.checkValidity() === true) {
         writeReviewApiRequest(product._id, formInputs)
         .then(data => {
             if (data === "review created") {
                 setProductReviewed("Hai pubblicato la tua recensione");
             }
            })
         .catch((er) => setProductReviewed(er.response.data.message ? er.response.data.message : er.response.data));
         setShowProductReviewed(true);
        }
  }

/*  
  const currency = function(number) {
    return new Intl.NumberFormat(
            'it-IT',
            {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}
          )
        .format(number);
  };

  const euroCurrency = function(number) {
    const str=currency(number);
    return str.substring(str.length-1)+' '+str.substring(0,str.length-1)
  }
*/

  const handleChange = (event: SelectChangeEvent) => {
    setQuantity(event.target.value);
  };

  const handleSelectRating = (event: SelectChangeEvent) => {
    setRating(event.target.value);
  };

  return (
      <>
      <MetaComponent title={product.name} description={product.description}/>
      <Container>
      <AddedToCartMessageComponent
        showCartMessage={showCartMessage}
        setShowCartMessage={setShowCartMessage}
      />
      <Row className="mt-5">
        {loading ? (
          <h2>Caricamento dettagli prodotto ...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <>

            {/* INI colonna tre immagini  */}
                       
            <Col style={{ zIndex: 1 }} md={4}>

              <div class="carousel-wrapper">
                <Carousel infiniteLoop useKeyboardArrows showArrows={true}>

                  {product.images.map((slide, i) => (
                    <div
                      key={i}
                    >
                      <img
                        src={slide.path}
                        className='d-block w-100'
                        alt={slide.path}
                      />
                    </div>
                  ))}

                </Carousel>
              </div>

            </Col>

            {/* FIN colonna tre immagini  */}            

            <Col md={6}>

              <Row>

                <Col md={6}>

                  <ListGroup variant="flush" 
                    style={{
                      border: "0",
                    }}
                  >
                    <ListGroup.Item 
                      style={{
                        border: "0",
                      }}
                    >
                      <h1>{product.name}</h1>
                    </ListGroup.Item>
                    
                    <ListGroup.Item 
                      style={{
                        border: "0",
                      }}
                    >
                      <Rating
                        readonly
                        size={20}
                        initialValue={product.rating}
                      />{" "}
                      ({product.reviewsNumber ? product.reviewsNumber : 0})
                      </ListGroup.Item>

                    <ListGroup.Item 
                      style={{
                        border: "0",
                      }}
                    >
                      Prezzo <span className="fw-bold">{strEuroCurrency(product.price)}</span>
                    </ListGroup.Item>

                    <ListGroup.Item>{product.description}</ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col md={6}>

                  <Box
                      sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                      noValidate
                      autoComplete="off"
                    >

                      <TextField id="id-name" label="Disponibile" variant="outlined" value={product.count > 0 ? "in stock" : "out of stock"} 
                        size="small"
                        slotProps={{
                          input: { readOnly: true },
                          inputLabel: { shrink: true }
                        }}             
                        onChange={(event) => {
                        //setName(event.target.value);
                        }}
                      />
                     
                      <TextField id="id-name" label="Prezzo" variant="outlined" value={strEuroCurrency(product.price)} 
                        size="small"
                        slotProps={{
                          input: { readOnly: true },
                          inputLabel: { shrink: true },
                        }}             
                        onChange={(event) => {
                        //setName(event.target.value);
                        }}
                      />

                      <TextField id="id-quantity" label="Quantità" variant="outlined" value={quantity} type="number" 
                        size="small"
                        slotProps={{
                          inputLabel: { shrink: true },
                        }}     
                        InputProps={{
                          inputProps: { 
                              max: 100, min: 0 
                          }
                        }}    
                        onChange={(event) => {
                          setQuantity(event.target.value);
                        }}
                      />

        {/* 
           
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Quantità</InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={quantity}
                          label="Quantità"
                          onChange={handleChange}
                          >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
        */}
           
                      <Button onClick={addToCartHandler} variant="success">
                        Aggiungi al carrello
                      </Button>

                  </Box>


                </Col>
              </Row>
              <Row>
                <Col className="mt-5">

                <Box
                      sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                      noValidate
                      autoComplete="off"
                    >

                  <h5>RECENSIONI</h5>

                  <ListGroup variant="flush">
                    {product.reviews &&
                      product.reviews.map((review, idx) => (
                        <ListGroup.Item key={idx}>
                          {review.user.name} <br />
                          <Rating readonly size={20} initialValue={review.rating} />
                          <br />
                          {review.createdAt.substring(8, 10)+'/'+review.createdAt.substring(5, 7)+'/'+review.createdAt.substring(0, 4)} <br />
                          {review.comment}
                        </ListGroup.Item>
                      ))}
                      <div ref={messagesEndRef} />
                  </ListGroup>
                  </Box>

                </Col>
              </Row>


              {!userInfo.name && <Alert variant="danger">Fai login per scrivere una recensione</Alert>}
              
              <Form onSubmit={sendReviewHandler}>
                
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >

        {/* 
                  <Form.Label>Scrivi una recensione</Form.Label>
                  <Form.Control name="comment" required as="textarea" disabled={!userInfo.name} rows={3} />
                      value={comment}
        */}

                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-static"
                      label="Scrivi una recensione"
                      multiline
                      rows={3}
                      maxRows={6}
                      disabled={!userInfo.name}
                      variant="outlined"
                      onChange={(event) => {
                        setComment(event.target.value);
                      }}                      
                    />                
                  </FormControl>

                </Form.Group>

                <Row>
                  <Col md={4}>
                      <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Seleziona le stelle</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={rating}
                          defaultValue=""
                          label="Seleziona le stelle"
                          onChange={handleSelectRating}
                          disabled={!userInfo.name}
                          onSubmit={sendReviewHandler}
                          >                          
                        <MenuItem value={1}><Star /></MenuItem>
                        <MenuItem value={2}><Star/><Star/></MenuItem>
                        <MenuItem value={3}><Star/><Star/><Star/></MenuItem>
                        <MenuItem value={4}><Star/><Star/><Star/><Star/></MenuItem>
                        <MenuItem value={5}><Star/><Star/><Star/><Star/><Star/></MenuItem>
                      </Select>
                    </FormControl>
                  </Col>

                  <Col md={6}>

                    {/* 
                            <Form.Select name="rating" required disabled={!userInfo.name} aria-label="Default select example">
                    */}

                    <Button disabled={!userInfo.name} type="submit" className="mb-3 mt-3" variant="primary">
                      Invia recensione
                    </Button>{" "}
                  </Col>
                </Row>

                <Alert
                    show={showProductReviewed}
                    variant="success"
                    onClose={() => setShowProductReviewed(false)}
                    dismissible
                  >
                    <Alert.Heading>{productReviewed}</Alert.Heading>
                </Alert>
                  
              </Form>

            </Col>
          </>
        )}
      </Row>
    </Container>
      </>
    
  );
};

export default ProductDetailsPageComponent;
