import AdminGiftcardsPageComponent from "./components/AdminGiftcardsPageComponent";

import axios from "axios";

const fetchGiftcards = async (abctrl) => {
    const {data} = await axios.get("/api/giftcards", {
        signal: abctrl.signal,
    });
    return data
}

const deleteGiftcard = async (userId) => {
    const { data } = await axios.delete(`/api/giftcards/${userId}`);
    return data
}

const AdminGiftcardsPage = () => {
  return <AdminGiftcardsPageComponent fetchGiftcards={fetchGiftcards} deleteGiftcard={deleteGiftcard} />;
};

export default AdminGiftcardsPage;