import { Row, Col, Container, Form, Button, Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

import axios from "axios";
import { uploadGiftcardImagesApiRequest, uploadImagesCloudinaryApiRequest } from "../utils/utils";

const onHover = {
  cursor: "pointer",
  position: "absolute",
  left: "5px",
  top: "-10px",
  transform: "scale(2.7)",
};

const EditGiftcardPageComponent = ({ updateGiftcardApiRequest, fetchGiftcard }) => {

  const [validated, setValidated] = useState(false);
  const [giftcard, setGiftcard] = useState([]);
  const [updateGiftcardResponseState, setUpdateGiftcardResponseState] = useState({ message: "", error: "" });

  const [isUploading, setIsUploading] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false)

  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [note, setNote] = useState("");                      
  const [code, setCode] = useState("");                      
  const [value, setValue] = useState("");                      
  const [percentual, setPercentual] = useState("");                      
  const [categories, setCategories] = useState([]);                      
  const [products, setProducts] = useState([]);                      
  const [startDate, setStartDate] = useState("");                      
  const [endDate, setEndDate] = useState("");                      
  const [active, setActive] = useState(false);                      
  const [image, setImage] = useState("");                      

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget.elements;

    const name = form.name.value;
    const description = form.description.value;
    const color = form.color.value;
    const image = form.image.value;

    if (event.currentTarget.checkValidity() === true) {
        updateGiftcardApiRequest(id, name, description, color, image)
        .then(data => {
            if (data.success === "giftcard updated") {
                navigate("/admin/giftcards");
            }
          })
        .catch(er => {
            setUpdateGiftcardResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
        })
    }

    setValidated(true);
  };

    useEffect(() => {
      fetchGiftcard(id)
      .then(data => {
          setGiftcard(data);
      })
      .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
      //console.log('giftcard = ',giftcard)
  }, [id])

  useEffect(() => {
    fetchGiftcard(id)
    .then(data => {
      console.log(`data =`, data);  
      setName(data?.name);
      setDescription(data?.description);
      setNote(data?.note);
      setCode(data?.code);
      setValue(data?.value);
      setPercentual(data?.percentual);
      setCategories(data?.categories);
      setProducts(data?.products);
      setStartDate(data?.startDate);
      setEndDate(data?.endDate);
      setActive(data?.active);
      setImage(data?.image);
      console.log(`name  =`, name);  
      console.log(`description  =`, description);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
},[id])


function salvaGiftcardModificata (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`[salvaGiftcardModificata] id =`, id);  
  console.log(`[salvaGiftcardModificata] name dig =`, name);  
  console.log(`[salvaGiftcardModificata] description dig =`, description);  
  console.log(`[salvaGiftcardModificata] image dig =`, image);  

  updateGiftcardApiRequest(id, name, description, note, code, value, percentual, categories, products, startDate, endDate, active, image)
  .then(data => {
      if (data.success === "resource updated") {
          navigate("/admin/giftcards");
      }
    })
  .catch(er => {
      setUpdateGiftcardResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
  })

  window.location.assign('/admin/giftcards')
}

function gotoIndietro (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  window.location.assign('/admin/giftcards')
}

const imageGiftcardDeleteHandler = async (imagePath, giftcardId) => {
    //console.log("[imageGiftcardDeleteHandler] giftcardId =",giftcardId)
    //console.log("[imageGiftcardDeleteHandler] imagePath =",imagePath)

    let encoded = encodeURIComponent(imagePath)
    if (process.env.NODE_ENV !== "production") {
      await axios.delete(`/api/giftcards/admin/image/${encoded}/${giftcardId}`);
    } else {
      await axios.delete(`/api/giftcards/admin/image/${encoded}/${giftcardId}`);
    }
}

  return (

    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Modifica giftcard</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
                style={{
                  width: "300px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-description" label="Descrizione" variant="outlined" value={description} defaultValue={description} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

            <TextField id="id-note" label="Note" variant="outlined" value={note} defaultValue={note} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setNote(event.target.value);
                }}
              />

            <TextField id="id-code" label="Codice" variant="outlined" value={code} defaultValue={code} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />

            <TextField id="id-value" label="Importo" variant="outlined" value={value} defaultValue={value} type="number" 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
              />

            <TextField id="id-percentual" label="Percentuale" variant="outlined" value={percentual} defaultValue={percentual} type="number"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPercentual(event.target.value);
                }}
              />

            <TextField id="id-startDate" label="Data di inizio" variant="outlined" value={startDate} defaultValue={startDate} type="date"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />

              <TextField id="id-endDate" label="Data di fine" variant="outlined" value={endDate} defaultValue={endDate} type="date"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
              />         

            </Box>


            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label>Immagini</Form.Label>
              <Row>
                {giftcard.images &&
                  giftcard.images.map((image, idx) => (
                    <Col key={idx} style={{ position: "relative" }} xs={3}>
                      <Image
                        crossOrigin="anonymous"
                        src={image.path ?? null}
                        fluid
                      />
                      <i style={onHover} onClick={() => imageGiftcardDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                    </Col>
                  ))}
              </Row>
              <Form.Control  type="file" multiple onChange={e => {
                 setIsUploading("upload files in corso ..."); 
                 if (process.env.NODE_ENV !== "production") {
                     uploadGiftcardImagesApiRequest(e.target.files, id)
                     .then(data => {
                         setIsUploading("upload files completato");
                         setImageUploaded(!imageUploaded);
                     })
                      .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                 } else {
                  uploadGiftcardImagesApiRequest(e.target.files, id)
                  .then(data => {
                      setIsUploading("upload files completato");
                      setImageUploaded(!imageUploaded);
                  })
                   .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                  }
              }} />
               {isUploading}
            </Form.Group>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaGiftcardModificata(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             

          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>


    );
};

export default EditGiftcardPageComponent;
