import {
  Row,
  Col,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const CreateCouponPageComponent = ({
  createCouponApiRequest,
  uploadCouponImagesApiRequest,
  uploadImagesCloudinaryApiRequest,
  reduxDispatch,
  newCoupon,
  deleteCoupon,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateCouponResponseState] = useState({
    message: "",
    error: "",
  });
 
  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [note, setNote] = useState("");                      
  const [code, setCode] = useState("");                      
  const [value, setValue] = useState("");                      
  const [percentual, setPercentual] = useState("");                      
  const [categories, setCategories] = useState([]);                      
  const [products, setProducts] = useState([]);                      
  const [startDate, setStartDate] = useState("");                      
  const [endDate, setEndDate] = useState("");                      
  const [active, setActive] = useState(false);                      
  const [image, setImage] = useState("");                      

  const navigate = useNavigate();

  const handleSubmit = (event) => {

    event.preventDefault();
    event.stopPropagation();
    
    console.log("submit")
    console.log("submit")
    
    const form = event.currentTarget.elements;
    
    const formInputs = {
      name: name,
      description: description,
      note: note,
      code: code,
      value: value,
      percentual: percentual,
      categories: categories,
      products: products,
      startDate: startDate,
      endDate: endDate,
      active: active,
      image: "",
    };
      
    if (event.currentTarget.checkValidity() === true) {
      if (images.length > 3) {
        setIsCreating("to many files");
        return;
      }

      createCouponApiRequest(formInputs)
        .then((data) => {
          if (images) {
            if (process.env.NODE_ENV !== "production") {
              uploadCouponImagesApiRequest(images, data.couponId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            } else {
              uploadCouponImagesApiRequest(images, data.couponId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            }
          }
          if (data.message === "coupon created") navigate("/admin/coupons");
        })
        .catch((er) => {
          setCreateCouponResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          });
        });
    }

    setValidated(true);

    window.location.assign('/admin/coupons')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/coupons')
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }  

  return (

    <Container fullWidth>
      
    <Row className="justify-content-center mt-5">

      <Col md={11}>
        <h1>&nbsp;Crea nuovo coupon</h1>

        <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

           <Box
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

            <TextField id="id-name" label="Nome" variant="outlined" 
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />

             <TextField id="id-description" label="Descrizione" variant="outlined" 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

            <TextField id="id-note" label="Note" variant="outlined" 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setNote(event.target.value);
                }}
              />

            <TextField id="id-code" label="Codice" variant="outlined" 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />

            <TextField id="id-value" label="Importo" variant="outlined" type="number"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
              />

            <TextField id="id-percentual" label="Percentuale" variant="outlined" type="number"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPercentual(event.target.value);
                }}
              />

            <TextField id="id-startDate" label="Data di inizio" variant="outlined" type="date"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />

              <TextField id="id-endDate" label="Data di fine" variant="outlined" type="date"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
              />
          </Box>

           <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label 
                style={{
                  color: "blue",
                }}
              >
                Immagine
              </Form.Label>

              <Form.Control
                style={{
                  width: "400px",
                }}
                required
                type="file"
                multiple
                onChange={(e) => uploadHandler(e.target.files)}
              />
              {isCreating}
            </Form.Group>

            <Row className="justify-content-center mt-5">
            
              <Col md={2}>
                <Button 
                  type="button"
                  className="btn btn-warning"
                  onClick={(e) => {gotoIndietro(e); }}
                  style={{
                    width: "80px",
                  }}
                >
                  Indietro
                </Button>             
              </Col>

              <Col md={2}>
                <Button variant="primary" type="submit">
                  Crea
                </Button>
              {createProductResponseState.error ?? ""}
            </Col>
            
          </Row>

        </Form>

      </Col>

    </Row>

  {/* 
    <Row className="justify-content-center mt-5">
      <Col md={1}>

        <Button 
            type="button"
            className="btn btn-warning"
            onClick={(e) => {gotoIndietro(e); }}
            style={{
              width: "80px",
            }}
          >
            Indietro
          </Button>             

       </Col>

        <Col md={1}>
          <Button 
            type="button"
            className="btn btn-primary"
            onClick={(e) => {createNewCoupon(e); }}
            style={{
              width: "80px",
            }}
          >
            Crea
          </Button>             

          {createProductResponseState.error ?? ""}
        </Col>
    </Row>
    */}

    <Row className="mt-5 justify-content-md-center"></Row>

  </Container>  


  );
};

export default CreateCouponPageComponent;
