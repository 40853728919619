import EditCouponPageComponent from "./components/EditCouponPageComponent";

import axios from "axios";

const fetchCoupon = async (couponId) => {
    const { data } = await axios.get(`/api/coupons/${couponId}`);
    return data;
}

const updateCouponApiRequest = async (couponId, name, description, note, code, value, percentual, categories, products, startDate, endDate, active, image) => {
    const { data } = await axios.put(`/api/coupons/${couponId}`, { name, description, note, code, value, percentual, categories, products, startDate, endDate, active, image });
    return data;
}

const AdminEditCouponPage = () => {
  
  return <EditCouponPageComponent updateCouponApiRequest={updateCouponApiRequest} fetchCoupon={fetchCoupon} />;
};


export default AdminEditCouponPage;
