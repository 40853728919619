import * as actionTypes from "../constants/couponConstants";

import axios from "axios";

export const getCoupons = () => async (dispatch) => {
    const { data } = await axios.get("/api/coupons");
    dispatch({
        type: actionTypes.GET_COUPONS_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, couponChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/coupons/attr", { key, val, couponChoosen }); 
   if (data.couponUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.couponUpdated],
       })
   }
}

export const newCoupon = (coupon) => async (dispatch, getState) => {
    const res = getState().getCoupons.coupons;
    const { data } = await axios.post("/api/coupons", { coupon });
    if (data.couponCreated) {
        dispatch({
            type: actionTypes.INSERT_COUPON,
            payload: [...res, data.couponCreated],
        })
    }
}

export const deleteCoupon = (coupon) => async (dispatch, getState) => {
    const res = getState().getCoupons.coupons;
    const coupons = res.filter((item) => item.name !== coupon);
    const { data } = await axios.delete("/api/coupons/" + encodeURIComponent(coupon));
    if (data.couponDeleted) {
        dispatch({
           type: actionTypes.DELETE_COUPON, 
           payload: [...coupons],
        })
    }
}