import CreateGiftcardPageComponent from "./components/CreateGiftcardPageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newGiftcard,deleteGiftcard, saveAttributeToCatDoc } from "../../redux/actions/giftcardActions";
import { useDispatch } from "react-redux";

const createGiftcardApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/giftcards`, { ...formInputs });
  return data;
};

const AdminCreateGiftcardPage = () => {
  const { giftcards } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateGiftcardPageComponent
      createGiftcardApiRequest={createGiftcardApiRequest}
      giftcards={giftcards}
      reduxDispatch={dispatch}
      newGiftcard={newGiftcard}
      deleteGiftcard={deleteGiftcard}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateGiftcardPage;
