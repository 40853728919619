import dayjs from 'dayjs';

export function strDateITAfromDayJs(dateDayjs) {
  return dateDayjs.format('DD-MM-YYYY');
};

export const strDateUSAfromDayJs = (dateDayjs)  => {
  return dateDayjs.format('YYYY-MM-DD');
};

export const strDateITAfromStrUSA = (strDayUSA)  => {  
  return dayjs(strDayUSA).format('DD/MM/YYYY');
};

export const strDateUSAfromStrITA = (strDayITA)  => {  
  return dayjs(strDayITA).format('YYYY-MM-DD');
};
