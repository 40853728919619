import axios from "axios";

export const uploadImagesApiRequest = async (images, productId) => {

  console.log("[uploadImagesApiRequest]")

  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  const { data } = await axios.post("/api/products/admin/upload?productId=" + productId, formData);
  return data;
};

export const uploadCategoryImagesApiRequest = async (images, categoryId) => {

    console.log("[uploadCategoryImagesApiRequest]")
    const formData = new FormData();
    Array.from(images).forEach((image) => {
      formData.append("images", image);
    });
    const { data } = await axios.post("/api/categories/upload?categoryId=" + categoryId, formData);
    return data;
};

export const uploadCouponImagesApiRequest = async (images, couponId) => {

  console.log("[uploadCouponImagesApiRequest]")
  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  const { data } = await axios.post("/api/coupons/upload?couponId=" + couponId, formData);
  return data;
};

export const uploadGiftcardImagesApiRequest = async (images, giftcardId) => {

  console.log("[uploadGiftcardImagesApiRequest]")
  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  const { data } = await axios.post("/api/giftcards/upload?giftcardId=" + giftcardId, formData);
  return data;
};

export const uploadImagesCloudinaryApiRequest = async (images, categoryId) => {
  // la stessa function anche per Cloudinary
  console.log("[uploadCategoryImagesApiRequest]")
  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  const { data } = await axios.post("/api/categories/upload?categoryId=" + categoryId, formData);
  return data;
};

/*
export const uploadImagesCloudinaryApiRequest = (images,productId) => {
    const url = "https://api.cloudinary.com/v1_1/jkhbnc-366gs/image/upload";
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append("file", file);
        formData.append("upload_preset", "acsijtdn");
        fetch(url, {
            method: "POST",
            body: formData,
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            axios.post("/api/products/admin/upload?cloudinary=true&productId=" + productId, data);
        })
    }
}
*/
