import { Container, Row, Col, Alert, ListGroup, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CartItemComponent from "../../components/CartItemComponent";
import { Box } from "@mui/material";
import TextField from '@mui/material/TextField';

const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')

const CartPageComponent = ({
  addToCart,
  removeFromCart,
  cartItems,
  cartSubtotal,
  reduxDispatch,
}) => {
  const changeCount = (productID, count) => {
    reduxDispatch(addToCart(productID, count));
  };

  const removeFromCartHandler = (productID, quantity, price) => {
     if (window.confirm("Sei sicuro?")) {
         reduxDispatch(removeFromCart(productID, quantity, price));
     } 
  }

  /*
  const currency = function(number) {
    return new Intl.NumberFormat(
            'it-IT',
            {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}
          )
        .format(number);
  };

  const euroCurrency = function(number) {
    const str=currency(number);
    return str.substring(str.length-1)+' '+str.substring(0,str.length-1)
  }
  */

  const numItems = function(nItems) {
    return nItems === 1 ? nItems+" prodotto" : nItems+" prodotti"
  }  

  const totaleItems = function(items) {
    let nrItems=0;
    items.forEach(function (item, idx) {
      console.log(' item =',item)
      nrItems=nrItems + parseInt(item.quantity);

    })
    return nrItems === 1 ? nrItems+" prodotto" : nrItems+" prodotti"
  }  

  return (
    <Container fluid>

      <Row className="mt-4">
      
        <Col md={8}>

          <h1>Carrello</h1>
        
          {cartItems.length === 0 ? (
            <Alert variant="info">Il tuo carrello è vuoto</Alert>
          ) : (
            <ListGroup variant="flush">
              {cartItems.map((item, idx) => (
                <CartItemComponent
                  item={item}
                  key={idx}
                  changeCount={changeCount}
                  removeFromCartHandler={removeFromCartHandler}
                />
              ))}
            </ListGroup>
          )}
        </Col>

        <Col md={3}>

          <Box
            sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >

            <h4>Totale provvisorio</h4> 
            
            <TextField id="id-name" label="nr prodotti" variant="outlined" value={totaleItems(cartItems)} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />
            <TextField id="id-name" label="Totale" variant="outlined" value={strEuroCurrency(cartSubtotal)} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <LinkContainer to="/user/cart-details">
              <Button disabled={cartSubtotal === 0} type="button" variant="success">Procedi al Checkout</Button>
            </LinkContainer>

          </Box>

        </Col>

      </Row>

        {/* 
          <ListGroup>
            <ListGroup.Item>

              <Row>
                <Col md={8}>
                  <h4>Totale provvisorio</h4> 
                </Col>
                <Col md={4}>
                <h6>({cartItems.length} {cartItems.length === 1 ? "Prodotto" : "Prodotti"})</h6>
                </Col>
             </Row>

            </ListGroup.Item>

            <ListGroup.Item>
              Prezzo: <span className="fw-bold">{euroCurrency(cartSubtotal)}</span>
            </ListGroup.Item>

            <ListGroup.Item>
              <LinkContainer to="/user/cart-details">
                <Button disabled={cartSubtotal === 0} type="button">Procedi al Checkout</Button>
              </LinkContainer>
            </ListGroup.Item>
          </ListGroup>
        */}

    </Container>
  );
};

export default CartPageComponent;
