import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeCategory, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

const config = require("../../../constants/constants.js")

const CreateProductPageComponent = ({
  createProductApiRequest,
  uploadImagesApiRequest,
  uploadImagesCloudinaryApiRequest,
  categories,
  reduxDispatch,
  newCategory,
  deleteCategory,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateProductResponseState] = useState({
    message: "",
    error: "",
  });
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget.elements;
/*
    const formInputs = {
      name: form.name.value,
      description: form.description.value,
      color: form.color.value,
      count: form.count.value,
      price: form.price.value,
      category: form.category.value,
      attributesTable: attributesTable,
    };
*/
    const formInputs = {
      name: name,
      description: description,
      color: color,
      count: count,
      price: price,
      category: form.category.value,
      attributesTable: attributesTable,
    };

    console.log('formInputs=',formInputs)
    console.log('formInputs=',formInputs)

    if (event.currentTarget.checkValidity() === true) {
      if (images.length > config.NUMBER_IMAGE_UPDATING_PRODUCT) {
        setIsCreating("to many files");
        return;
      }
      createProductApiRequest(formInputs)
        .then((data) => {
          if (images) {
            if (process.env.NODE_ENV !== "production") {
              uploadImagesApiRequest(images, data.productId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            } else {
              uploadImagesApiRequest(images, data.productId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            }
          }
          if (data.message === "product created") navigate("/admin/products");
        })
        .catch((er) => {
          setCreateProductResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          });
        });
    }

    setValidated(true);
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newCategoryHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newCategory(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setCategoryChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteCategoryHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteCategory(element.value));
    setCategoryChoosen("Choose category");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, categoryChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

    const [name, setName] = useState("");                      
    const [description, setDescription] = useState("");                      
    const [color, setColor] = useState("");                      
    const [count, setCount] = useState(0);                      
    const [price, setPrice] = useState(0);                      
  
    function gotoIndietro (eventKey) {
      eventKey.preventDefault();
      eventKey.stopPropagation();
  
      window.location.assign('/admin/products')
    }
  
  return (

    <Container>
    
      <Row className="justify-content-md-center mt-5">
    
        {/* 
        <Col md={1}>
          <Link to="/admin/products" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        */}
    
        <Col md={11}>

          <h1>Crea nuovo prodotto</h1>
        
          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >
        

        {/* 
            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Nome
              </InputGroup.Text>
              <Form.Control name="name" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Descrizione
              </InputGroup.Text>
              <Form.Control name="description" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Colore
              </InputGroup.Text>
              <Form.Control name="color" required type="color" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Quantità
              </InputGroup.Text>
              <Form.Control name="count" required type="number" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Prezzo
              </InputGroup.Text>
              <Form.Control name="price" required type="text" />
            </InputGroup>
            */} 

            <Box
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

            <TextField id="id-name" label="Nome" variant="outlined" 
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />

             <TextField id="id-description" label="Descrizione" variant="outlined" 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

            <TextField id="id-color" label="Colore" variant="outlined" type="color"
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setColor(event.target.value);
              }}
            />

            <TextField id="id-count" label="Quantità" variant="outlined" type="number"
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setCount(event.target.value);
              }}
            />

            <FormControl sx={{ m: 1 }}
              style={{
                width: "200px",
              }}    
              onChange={(event) => {
                setPrice(event.target.value);
              }}        
            >
              <InputLabel htmlFor="outlined-adornment-amount">Prezzo</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                label="Prezzo"
                type="number"
              />
            </FormControl>

            </Box>

            <Form.Group className="mb-3" controlId="formBasicCategory">
              <Form.Label
                style={{
                  color: "blue",
                }}
              >
                Categoria
                <CloseButton onClick={deleteCategoryHandler} />(
                <small>rimuovi selezione</small>)
              </Form.Label>
              <Form.Select
                style={{
                  width: "400px",
                }}
                id="cats"
                required
                name="category"
                aria-label="Default select example"
                onChange={(e) =>
                  changeCategory(
                    e,
                    categories,
                    setAttributesFromDb,
                    setCategoryChoosen
                  )
                }
              >
                <option value="">Scegli categoria</option>
                {categories.map((category, idx) => (
                  <option key={idx} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>


        {/* 
            <Form.Group className="mb-3" controlId="formBasicNewCategory">
              <Form.Label>
                Crea nuova categoria (es. Computers/Laptops/Intel){" "}
              </Form.Label>
              <Form.Control
                onKeyUp={newCategoryHandler}
                name="newCategory"
                type="text"
              />
            </Form.Group>

            {attributesFromDb.length > 0 && (
              <Row className="mt-5">
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicAttributes">
                    <Form.Label>Scegli attributo e imposta valore</Form.Label>
                    <Form.Select
                      name="atrrKey"
                      aria-label="Default select example"
                      ref={attrKey}
                      onChange={(e)=>setValuesForAttrFromDbSelectForm(e, attrVal, attributesFromDb)}
                    >
                      <option>Scegli attributo</option>
                      {attributesFromDb.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <option value={item.key}>{item.key}</option>
                        </React.Fragment>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicAttributeValue"
                  >
                    <Form.Label>Valore attributo</Form.Label>
                    <Form.Select
                    onChange={attributeValueSelected}
                      name="atrrVal"
                      aria-label="Default select example"
                      ref={attrVal}
                    >
                      <option>Scegli il valore dell'attributo</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              {attributesTable.length > 0 && (
                <Table hover>
                  <thead>
                    <tr>
                      <th>Attributo</th>
                      <th>Valore</th>
                      <th>Cancella</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attributesTable.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.key}</td>
                        <td>{item.value}</td>
                        <td>
                          <CloseButton onClick={() => deleteAttribute(item.key)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicNewAttribute">
                  <Form.Label>Nuovo attributo</Form.Label>
                  <Form.Control
                  ref={createNewAttrKey}
                    disabled={["", "Choose category"].includes(categoryChoosen)}
                    placeholder="scegli o crea una categoria"
                    name="newAttrValue"
                    type="text"
                    onKeyUp={newAttrKeyHandler}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicNewAttributeValue"
                >
                  <Form.Label>Valore attibuto</Form.Label>
                  <Form.Control
                  ref={createNewAttrVal}
                    disabled={["", "Choose category"].includes(categoryChoosen)}
                    placeholder="scegli o crea una categoria"
                    required={newAttrKey}
                    name="newAttrValue"
                    type="text"
                    onKeyUp={newAttrValueHandler}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Alert show={newAttrKey && newAttrValue} variant="primary">
              Dopo aver digitato la chiave e il valore dell'attributo, premere Invio su uno dei campi
            </Alert>
        */}

            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label                 
                style={{
                  color: "blue",
                }}
              >
                Immagini
              </Form.Label>
              <Form.Control
                style={{
                  width: "400px",
                }}
                required
                type="file"
                multiple
                onChange={(e) => uploadHandler(e.target.files)}
              />
              {isCreating}
            </Form.Group>

            <Row className="justify-content-center mt-5">
            
              <Col md={2}>
                <Button 
                    type="button"
                    className="btn btn-warning"
                    onClick={(e) => {gotoIndietro(e); }}
                    style={{
                      width: "80px",
                    }}
                  >
                    Indietro
                </Button>  
              </Col>

              <Col md={2}>
                <Button variant="primary" type="submit">
                  Crea
                </Button>
                {createProductResponseState.error ?? ""}
              </Col>
              
            </Row>

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default CreateProductPageComponent;
