import { Row, Col, Image, ListGroup, Form, Button } from "react-bootstrap";
import RemoveFromCartComponent from "./RemoveFromCartComponent";

const { strCurrency, strEuroCurrency } = require('../utils/currencyUtils.js')

const CartItemComponent = ({ item, removeFromCartHandler = false, orderCreated = false, changeCount = false }) => {

/*
  const currency = function(number) {
    return new Intl.NumberFormat(
            'it-IT',
            {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}
          )
        .format(number);
  };

  const euroCurrency = function(number) {
    const str=currency(number);
    return str.substring(str.length-1)+' '+str.substring(0,str.length-1)
  }
*/

  return (
    <>
      <ListGroup.Item>
        <Row>
          <Col md={2}>
            <Image
              crossOrigin="anonymous"
              src={item.image ? item.image.path ?? null : null}
              fluid
            />
          </Col>

          <Col md={3}>{item.name}</Col>
          
          <Col md={2}>
            <b>{strCurrency(item.price)}</b>
          </Col>
          
          <Col md={2}>
            <Form.Select onChange={changeCount ? (e) => changeCount(item.productID, e.target.value) : undefined } disabled={orderCreated} value={item.quantity}>
              {[...Array(item.count).keys()].map((x) => (
                <option key={x + 1} value={x + 1}>
                  {x + 1}
                </option>
              ))}
            </Form.Select>
          
          </Col>
          
          <Col md={3}>
            <RemoveFromCartComponent
            orderCreated={orderCreated}
            productID={item.productID}
            quantity={item.quantity}
            price={item.price}
            removeFromCartHandler={removeFromCartHandler ? removeFromCartHandler : undefined}
             />
          </Col>
        </Row>
      </ListGroup.Item>
      <br />
    </>
  );
};

export default CartItemComponent;
