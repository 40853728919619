import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  ListGroup,
  Button,
} from "react-bootstrap";
import CartItemComponent from "../../../components/CartItemComponent";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import TextField from '@mui/material/TextField';
import { LinkContainer } from "react-router-bootstrap";

const { strCurrency, strEuroCurrency } = require('../../../utils/currencyUtils.js')

const OrderDetailsPageComponent = ({ getOrder, markAsDelivered }) => {

  const { id } = useParams();
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [isPaid, setIsPaid] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [orderButtonMessage, setOrderButtonMessage] = useState("Imposta come consegnato");
  const [cartItems, setCartItems] = useState([]);

  const [shipping, setShipping] = useState("inclusa");
  const [tax, setTax] = useState("inclusa");

  const styleSpecTitle1 = {
    color: "darkBlue",
  };

  useEffect(() => {
    getOrder(id)
      .then((order) => {
        setUserInfo(order.user);
        setPaymentMethod(order.paymentMethod);
        order.isPaid ? setIsPaid(order.paidAt) : setIsPaid(false);
        order.isDelivered
          ? setIsDelivered(order.deliveredAt)
          : setIsDelivered(false);
        setCartSubtotal(order.orderTotal.cartSubtotal);
        if (order.isDelivered) {
          setOrderButtonMessage("Ordine concluso");
          setButtonDisabled(true);
        }
        setCartItems(order.cartItems);
      })
      .catch((er) =>
      dispatch(logout())
        // console.log(
        //   er.response.data.message ? er.response.data.message : er.response.data
        // )
      );
  }, [isDelivered, id]);

/*  
  const currency = function(number) {
    return new Intl.NumberFormat(
            'it-IT',
            {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}
          )
        .format(number);
  };

  const euroCurrency = function(number) {
    const str=currency(number);
    return str.substring(str.length-1)+' '+str.substring(0,str.length-1)
  }
*/

  const numItems = function(nItems) {
    return nItems === 1 ? nItems+" prodotto" : nItems+" prodotti"
  }  

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();
  
    window.location.assign('/admin/coupons')
  }
  return (

    <Container fluid>
    
      <Row className="mt-4">
        <h1>
          dettagli dell'ordine {" "}
          <LinkContainer to="/admin/orders">
            <Button variant="primary" >
              Indietro
            </Button>
          </LinkContainer>
        </h1>

        <Col md={8}>
          <br />
          <Row>
            <Col md={6}>
              <h4 
                style={{
                  color: "#1136a0",
                 }}>
                Spedizione
              </h4>
              <b>Nome</b>: {userInfo.name} {userInfo.lastName} <br />
              <b>Indirizzo</b>: {userInfo.address} {userInfo.city}{" "}
              {userInfo.state} {userInfo.zipCode} <br />
              <b>Telefono</b>: {userInfo.phoneNumber}
            </Col>
            <Col md={6}>
              <h4 
                style={{
                  color: "#1136a0",
                 }}>
                Metodo di pagamento
              </h4>
              <Form.Select value={paymentMethod} disabled={true}
                style={{
                  width: "47ch",
                  }}
              >
                <option value="cc">Carta di credito</option>
                <option value="pp">PayPal</option>
                <option value="cod">Pagamento alla consegna (la consegna può ritardare)</option>
              </Form.Select>
            </Col>
            <Row>
              <Col>
                <Alert
                  className="mt-3"
                  variant={isDelivered ? "success" : "danger"}
                >
                  {isDelivered ? (<>Spedito il {isDelivered.substring(8, 10)+'/'+isDelivered.substring(5, 7)+'/'+isDelivered.substring(0, 4)}</>
                  ) : (
                    <>Non spedito</>
                  )}
                </Alert>
              </Col>
              <Col>
                <Alert className="mt-3" variant={isPaid ? "success" : "danger"}>
                  {isPaid ? <>Pagato il {isPaid.substring(8, 10)+'/'+isPaid.substring(5, 7)+'/'+isPaid.substring(0, 4)}</> : <>Non ancora pagato</>}
                </Alert>
              </Col>
            </Row>
          </Row>

          <br />

          <h4 
            style={{
              color: "#1136a0",
              }}>
            Articoli in ordine
          </h4>

          <ListGroup variant="flush">
            {cartItems.map((item, idx) => (
              <CartItemComponent key={idx} item={item} orderCreated={true} />
            ))}
          </ListGroup>
        </Col>

        <Col md={3}>


         <Box
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

            <h4 
              style={{
                color: "#1136a0",
                }}>
              Riepilogo ordine
            </h4>

           <TextField id="id-price" label="Prezzo articoli" variant="outlined" value={strEuroCurrency(cartSubtotal)} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <TextField id="id-shipping" label="spedizione" variant="outlined" value={shipping} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <TextField id="id-tax" label="iva" variant="outlined" value={tax} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <TextField id="id-totalPrice" label="Prezzo totale" variant="outlined" value={strEuroCurrency(cartSubtotal)} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <Button
              onClick={() => 
                markAsDelivered(id)
                .then((res) => {
                    if (res) {
                      setIsDelivered(true); 
                    } 
                })
                .catch(er => console.log(er.response.data.message ? er.response.data.message : er.response.data))
              }
              disabled={buttonDisabled}
              variant="success"
              type="button"
            >
              {orderButtonMessage}
            </Button>

          </Box>    

        </Col>
    </Row>

        {/* 
          <ListGroup>
            <ListGroup.Item>
              <h3 style={styleSpecTitle1}>Riepilogo ordine</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              Prezzo articolo:{" "}
              <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Spedizione: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Iva: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item className="text-danger">
              Prezzo totale: <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-grid gap-2">
                <Button
                  size="lg"
                  onClick={() => 
                    markAsDelivered(id)
                    .then((res) => {
                       if (res) {
                          setIsDelivered(true); 
                       } 
                    })
                    .catch(er => console.log(er.response.data.message ? er.response.data.message : er.response.data))
                  }
                  disabled={buttonDisabled}
                  variant="danger"
                  type="button"
                >
                  {orderButtonMessage}
                </Button>
              </div>
            </ListGroup.Item>
          </ListGroup>
        */}

    </Container>
  );
};

export default OrderDetailsPageComponent;
