import * as actionTypes from "../constants/couponConstants";

export const getCouponsReducer = (state = { coupons: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_COUPONS_REQUEST:
           return {
               ...state,
               coupons: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                coupons: action.payload,
            }
        case actionTypes.INSERT_COUPON:
            return {
                ...state,
                coupons: action.payload,
            }
        case actionTypes.DELETE_COUPON:
            return {
               ...state,
               coupons: action.payload,
            }
        default:
            return state;
    }
}