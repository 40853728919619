import EditGiftcardPageComponent from "./components/EditGiftcardPageComponent";

import axios from "axios";

const fetchGiftcard = async (giftcardId) => {
    const { data } = await axios.get(`/api/giftcards/${giftcardId}`);
    return data;
}

const updateGiftcardApiRequest = async (giftcardId, name, description, note, code, value, percentual, categories, products, startDate, endDate, active, image) => {
    const { data } = await axios.put(`/api/giftcards/${giftcardId}`, { name, description, note, code, value, percentual, categories, products, startDate, endDate, active, image });
    return data;
}

const AdminEditGiftcardPage = () => {
  
  return <EditGiftcardPageComponent updateGiftcardApiRequest={updateGiftcardApiRequest} fetchGiftcard={fetchGiftcard} />;
};


export default AdminEditGiftcardPage;
