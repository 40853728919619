import * as actionTypes from "../constants/giftcardConstants";

import axios from "axios";

export const getGiftcards = () => async (dispatch) => {
    const { data } = await axios.get("/api/giftcards");
    dispatch({
        type: actionTypes.GET_GIFTCARDS_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, giftcardChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/giftcards/attr", { key, val, giftcardChoosen }); 
   if (data.giftcardUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.giftcardUpdated],
       })
   }
}

export const newGiftcard = (giftcard) => async (dispatch, getState) => {
    const res = getState().getGiftcards.giftcards;
    const { data } = await axios.post("/api/giftcards", { giftcard });
    if (data.giftcardCreated) {
        dispatch({
            type: actionTypes.INSERT_GIFTCARD,
            payload: [...res, data.giftcardCreated],
        })
    }
}

export const deleteGiftcard = (giftcard) => async (dispatch, getState) => {
    const res = getState().getGiftcards.giftcards;
    const giftcards = res.filter((item) => item.name !== giftcard);
    const { data } = await axios.delete("/api/giftcards/" + encodeURIComponent(giftcard));
    if (data.giftcardDeleted) {
        dispatch({
           type: actionTypes.DELETE_GIFTCARD, 
           payload: [...giftcards],
        })
    }
}