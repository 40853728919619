import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  ListGroup,
  Button,
} from "react-bootstrap";
import CartItemComponent from "../../../components/CartItemComponent";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import TextField from '@mui/material/TextField';

const { strCurrency, strEuroCurrency } = require('../../../utils/currencyUtils.js')

const UserOrderDetailsPageComponent = ({
  userInfo,
  getUser,
  getOrder,
  loadPayPalScript,
}) => {
  const [userAddress, setUserAddress] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [isPaid, setIsPaid] = useState(false);
  const [orderButtonMessage, setOrderButtonMessage] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [isDelivered, setIsDelivered] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [shipping, setShipping] = useState("inclusa");
  const [tax, setTax] = useState("inclusa");

  const paypalContainer = useRef();

  const { id } = useParams();

  /*
  const currency = function(number) {
    return new Intl.NumberFormat(
            'it-IT',
            {style: 'currency', currency: 'EUR', minimumFractionDigits: 2}
          )
        .format(number);
  };

  const euroCurrency = function(number) {
    const str=currency(number);
    return str.substring(str.length-1)+' '+str.substring(0,str.length-1)
  }
  */

  const numItems = function(nItems) {
    return nItems === 1 ? nItems+" prodotto" : nItems+" prodotti"
  }  

  useEffect(() => {
    getUser()
      .then((data) => {
        setUserAddress({
          address: data.address,
          city: data.city,
          country: data.country,
          zipCode: data.zipCode,
          state: data.state,
          phoneNumber: data.phoneNumber,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getOrder(id)
      .then((data) => {
        setPaymentMethod(data.paymentMethod);
        setCartItems(data.cartItems);
        setCartSubtotal(data.orderTotal.cartSubtotal);
        data.isDelivered
          ? setIsDelivered(data.deliveredAt)
          : setIsDelivered(false);
        data.isPaid ? setIsPaid(data.paidAt) : setIsPaid(false);
        if (data.isPaid) {
          setOrderButtonMessage("Il tuo Ordine è concluso");
          setButtonDisabled(true);
        } else {
          if (data.paymentMethod === "cc") {
            setOrderButtonMessage("Paga il tuo Ordine con Carta");
          } if (data.paymentMethod === "pp") {
            setOrderButtonMessage("Paga il tuo Ordine con PayPal");
          } else if (data.paymentMethod === "cod") {
            setButtonDisabled(true);
            setOrderButtonMessage("Aspetta il tuo ordine. Paghi alla consegna");
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const orderHandler = () => {
    setButtonDisabled(true);
    if (paymentMethod === "pp") {
      setOrderButtonMessage(
        "Per pagare il tuo ordine clicca su uno dei pulsanti qui sotto"
      );
      if (!isPaid) {
        loadPayPalScript(cartSubtotal, cartItems, id, updateStateAfterOrder)
      }
    } else {
      setOrderButtonMessage("Il tuo Ordine è stato inviato");
    }
  };

  const updateStateAfterOrder = (paidAt) => {
      setOrderButtonMessage("Pagamento effettuato!");
      setIsPaid(paidAt);
      setButtonDisabled(true);
      paypalContainer.current.style = "display: none";
  }

  return (
    <Container fluid>

      <Row className="mt-4">
      
        <h1>Ordine</h1>
      
        <Col md={8}>
          <br />
          <Row>

            <Col md={6}>
      
              <h4 
                style={{
                  color: "#1136a0",
                 }}>
                Spedizione
              </h4>

              <b>Nome</b>: {userInfo.name} {userInfo.lastName} <br />
              <b>Indirizzo</b>: {userAddress.address} {userAddress.city}{" "}
              {userAddress.state} {userAddress.zipCode} <br />
              <b>Telefono</b>: {userAddress.phoneNumber}
            </Col>

            <Col md={6}>

              <h4 
                style={{
                  color: "#1136a0",
                 }}>
                Metodo di pagamento
              </h4>
              <Form.Select value={paymentMethod} disabled={true}
                style={{
                  width: "47ch",
                  }}
              >
                <option value="cc">Carta di credito</option>
                <option value="pp">PayPal</option>
                <option value="cod">Pagamento alla consegna (la consegna può ritardare)</option>
              </Form.Select>
            </Col>
            <Row>
              <Col>
                <Alert
                  className="mt-3"
                  variant={isDelivered ? "success" : "danger"}
                >
                  {isDelivered ? (<>Spedito il {isDelivered.substring(8, 10)+'/'+isDelivered.substring(5, 7)+'/'+isDelivered.substring(0, 4)}</>
                  ) : (
                    <>Non spedito</>
                  )}
                </Alert>
              </Col>
              <Col>
                <Alert className="mt-3" variant={isPaid ? "success" : "danger"}>
                  {isPaid ? <>Pagato il {isPaid.substring(8, 10)+'/'+isPaid.substring(5, 7)+'/'+isPaid.substring(0, 4)}</> : <>Non ancora pagato</>}
                </Alert>
              </Col>
            </Row>
          </Row>

          <br />

          <h4 
            style={{
              color: "#1136a0",
              }}>
            Articoli in ordine
          </h4>

          <ListGroup variant="flush">
            {cartItems.map((item, idx) => (
              <CartItemComponent item={item} key={idx} orderCreated={true} />
            ))}
          </ListGroup>
        </Col>

        <Col md={3}>
        
         <Box
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

            <h4 
              style={{
                color: "#1136a0",
                }}>
              Riepilogo ordine
            </h4>

           <TextField id="id-price" label="Prezzo articoli dopo tax" variant="outlined" value={strEuroCurrency(cartSubtotal)} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <TextField id="id-shipping" label="spedizione" variant="outlined" value={shipping} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <TextField id="id-tax" label="iva" variant="outlined" value={tax} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <TextField id="id-totalPrice" label="Totale da pagare" variant="outlined" value={strEuroCurrency(cartSubtotal)} 
              size="small"
              slotProps={{
                input: { readOnly: true },
                inputLabel: { shrink: true },
              }}             
              onChange={(event) => {
              //setName(event.target.value);
              }}
            />

            <Button 
              disabled={buttonDisabled} 
              type="button" 
              variant="success"
              onClick={orderHandler}
            >
              {orderButtonMessage}
            </Button>
            <div style={{ position: "relative", zIndex: 1 }}>
                <div ref={paypalContainer} id="paypal-container-element"></div>
            </div>

          </Box>            

        {/* 
            <ListGroup>

            <ListGroup.Item>
              <h3>Riepilogo ordine</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              Prezzo articolo (dopo tax):{" "}
              <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Spedizione: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Iva: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item className="text-danger">
              Prezzo totale: <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>

            <ListGroup.Item>
              <div className="d-grid gap-2">
                <Button
                  size="lg"
                  onClick={orderHandler}
                  variant="danger"
                  type="button"
                  disabled={buttonDisabled}
                >
                  {orderButtonMessage}
                </Button>
              </div>
              <div style={{ position: "relative", zIndex: 1 }}>
                <div ref={paypalContainer} id="paypal-container-element"></div>
              </div>
            </ListGroup.Item>
          </ListGroup>
        */}


        </Col>
      </Row>
    </Container>
  );
};

export default UserOrderDetailsPageComponent;
