import * as actionTypes from "../constants/giftcardConstants";

export const getGiftcardsReducer = (state = { giftcards: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_GIFTCARDS_REQUEST:
           return {
               ...state,
               giftcards: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                giftcards: action.payload,
            }
        case actionTypes.INSERT_GIFTCARD:
            return {
                ...state,
                giftcards: action.payload,
            }
        case actionTypes.DELETE_GIFTCARD:
            return {
               ...state,
               giftcards: action.payload,
            }
        default:
            return state;
    }
}