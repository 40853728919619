import CreateCouponPageComponent from "./components/CreateCouponPageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newCoupon,deleteCoupon, saveAttributeToCatDoc } from "../../redux/actions/couponActions";
import { useDispatch } from "react-redux";

const createCouponApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/coupons`, { ...formInputs });
  return data;
};

const AdminCreateCouponPage = () => {
  const { coupons } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateCouponPageComponent
      createCouponApiRequest={createCouponApiRequest}
      coupons={coupons}
      reduxDispatch={dispatch}
      newCoupon={newCoupon}
      deleteCoupon={deleteCoupon}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateCouponPage;
