import { Row, Col, Form, Container, 
  Button,
  Image,
 } from "react-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent.js";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import 'dayjs/locale/it.js';
import dayjs from 'dayjs'

// mui tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import TextField from '@mui/material/TextField';

import axios from "axios";
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const ConfigsPageComponent = ({
  fetchOrdersForFirstDate,
  fetchOrdersForSecondDate,
  socketIOClient
}) => {

  const navigate = useNavigate();

  const [firstDateToCompare, setFirstDateToCompare] = useState(
    new Date().toISOString().substring(0, 10)
  );
  var previousDay = new Date();
  previousDay.setDate(previousDay.getDate() - 1);

  const [page, setPage] = useState({});  
  
  const getPagesApiRequest = async () => {
    const url = `/api/pages`;
    const { data } = await axios.get(url);
    console.log("data=",data)
    return data
  }

  const [tabAttivo, setTabAttivo] = React.useState('home');

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) { 
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log('[handleChange] TAB newValue=',newValue); 
    if (newValue===0) readPageByMenuValue('HOME');
    if (newValue===1) readPageByMenuValue('NOVITA');
    if (newValue===2) readPageByMenuValue('BRAND');
    if (newValue===3) readPageByMenuValue('COUPON');
    if (newValue===4) readPageByMenuValue('GIFT CARD');
    if (newValue===5) readPageByMenuValue('CHI SIAMO');
    if (newValue===6) readPageByMenuValue('CONTATTI');
    if (newValue===7) readPageByMenuValue('LOGIN');
    if (newValue===8) readPageByMenuValue('ACCOUNT');
    setValue(newValue);
  };

  const [menuValue, setmenuValue] = useState("");                      
  const [menuLevel, setMenuLevel] = useState(0);                      
  const [menuSequence, setMenuSequence] = useState(0);                      
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [customizedContent, setCustomizedContent] = useState([]);     
  const [titleImg1, setTitleImg1] = useState("");                      
  const [subTitleImg1, setSubTitleImg1] = useState("");                      
  const [titleImg2, setTitleImg2] = useState("");                      
  const [subTitleImg2, setSubTitleImg2] = useState("");                      
  const [titleImg3, setTitleImg3] = useState("");                      
  const [subTitleImg3, setSubTitleImg3] = useState("");                      

  const [idConfig, setIdConfig] = useState("");                      
  const [ragioneSociale, setRagioneSociale] = useState("");                      
  const [cliente, setCliente] = useState("");                      
  const [partitaIva, setPartitaIva] = useState("");                      
  const [indirizzo, setIndirizzo] = useState("");                      
  const [cap, setCap] = useState("");                      
  const [citta, setCitta] = useState("");                      
  const [provincia, setProvincia] = useState("");                      
  const [telefono, setTelefono] = useState("");                      
  const [telefono1, setTelefono1] = useState("");                      
  const [telefono2, setTelefono2] = useState("");                      
  const [telefono3, setTelefono3] = useState("");                      
  const [mobile, setMobile] = useState("");                      
  const [mobile1, setMobile1] = useState("");                      
  const [mobile2, setMobile2] = useState("");                      
  const [mobile3, setMobile3] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [email1, setEmail1] = useState("");                      
  const [email2, setEmail2] = useState("");                      
  const [email3, setEmail3] = useState("");                      
  const [imageLogo, setImageLogo] = useState("");                      
  const [aliquotaIva, setAliquotaIva] = useState(0);                      
  const [aliquotaIvaRid, setAliquotaIvaRid] = useState(0);                      
  const [aliquotaIvaMin, setAliquotaIvaMin] = useState(0);                      
  const [aliquotaIva1, setAliquotaIva1] = useState(0);                      
  const [aliquotaIva2, setAliquotaIva2] = useState(0);                      
  const [aliquotaIva3, setAliquotaIva3] = useState(0);                      
  const [customerCode, setCustomerCode] = useState("");                      
  const [titleProduct, setTitleProduct] = useState("");                      
  const [siteStyleActive, setSiteStyleActive] = useState("");                      
  const [enableSwitchStyle, setEnableSwitchStyle] = useState("");                      
  const [urlSiteImageServer, setUrlSiteImageServer] = useState("");                      
  const [urlCustomerImageServer, setUrlCustomerImageServer] = useState("");                      

  const [updatePageResponseState, setUpdatePageResponseState] = useState({
    message: "",
    error: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget.elements;

    if (value===0 || value===1 || value===2 || value===3 || value===4 || value===5) 
    {
      const formInputs = {
        title: form.title.value,
        subTitle: form.subTitle.value,
        description: form.description.value,
        titleImg1: form.titleImg1.value,
        subTitleImg1: form.subTitleImg1.value,
        titleImg2: form.titleImg2.value,
        subTitleImg2: form.subTitleImg2.value,
        titleImg3: form.titleImg3.value,
        subTitleImg3: form.subTitleImg3.value,
      };

      console.log('[handleSubmit] formInputs =',formInputs);
  
      if (event.currentTarget.checkValidity() === true) {
        updatePageApiRequest(id, formInputs)
          .then((data) => {
            if (data.message === "page updated") navigate("/admin/configs");
          })
          .catch((er) =>
            setUpdatePageResponseState({
              error: er.response.data.message
                ? er.response.data.message
                : er.response.data,
            })
          );
      }
    } 
    if (value===6 || value===7 || value===8) 
    {
      const formInputs = {
        title: form.title.value,
        subTitle: form.subTitle.value,
        titleImg1: form.titleImg1.value,
        subTitleImg1: form.subTitleImg1.value,
        titleImg2: form.titleImg2.value,
        subTitleImg2: form.subTitleImg2.value,
        titleImg3: form.titleImg3.value,
        subTitleImg3: form.subTitleImg3.value,
      };

      console.log('[handleSubmit] formInputs =',formInputs);
  
      if (event.currentTarget.checkValidity() === true) {
        updatePageApiRequest(id, formInputs)
          .then((data) => {
            if (data.message === "page updated") navigate("/admin/configs");
          })
          .catch((er) =>
            setUpdatePageResponseState({
              error: er.response.data.message
                ? er.response.data.message
                : er.response.data,
            })
          );
      }
    }  
    if (value===9) 
    {

      let styleWeb=' ';
      if (siteStyleActive==='Classic') styleWeb='A';
      if (siteStyleActive==='Jazz') styleWeb='B';
      if (siteStyleActive==='Modern') styleWeb='C';
      if (siteStyleActive==='Time') styleWeb='D';
      console.log('[handleSubmit] styleWeb =',styleWeb);

      const formInputsConfig = {
        siteStyleActive: styleWeb,
        ragioneSociale: form.ragioneSociale.value,
        cliente: form.cliente.value,
        partitaIva: form.partitaIva.value,
        indirizzo: form.indirizzo.value,
        cap: form.cap.value,
        citta: form.citta.value,
        provincia: form.provincia.value,
        telefono: form.telefono.value,
        telefono1: form.telefono1.value,
        //telefono2: form.telefono2.value,
        //telefono3: form.telefono3.value,
        mobile: form.mobile.value,
        mobile1: form.mobile1.value,
        //mobile2: form.mobile2.value,
        //mobile3: form.mobile3.value,
        email: form.email.value,
        email1: form.email1.value,
        //email2: form.email2.value,
        //email3: form.email3.value,
        //imageLogo: form.imageLogo.value,
        aliquotaIva: form.aliquotaIva.value,
        aliquotaIvaRid: form.aliquotaIvaRid.value,
        aliquotaIvaMin: form.aliquotaIvaMin.value,
        //aliquotaIva1: form.aliquotaIva1.value,
        //aliquotaIva2: form.aliquotaIva2.value,
        //aliquotaIva3: form.aliquotaIva3.value,
        //customerCode: form.customerCode.value,
        //titleProduct: form.titleProduct.value,
        //siteStyleActive: form.siteStyleActive.value,
        //enableSwitchStyle: form.enableSwitchStyle.value,
        //urlSiteImageServer: form.urlSiteImageServer.value,
        //urlCustomerImageServer: form.urlCustomerImageServer.value,
      };

      console.log('[handleSubmit] formInputsConfig =',formInputsConfig);
  
      if (event.currentTarget.checkValidity() === true) {
        updateConfigApiRequest(idConfig, formInputsConfig)
          .then((data) => {
            if (data.message === "page updated") navigate("/admin/configs");
          })
          .catch((er) =>
            setUpdatePageResponseState({
              error: er.response.data.message
                ? er.response.data.message
                : er.response.data,
            })
          );
      }

   }

    setValidated(true);

    window.location.assign('/admin/configs')
  };  

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();
  
    window.location.assign('/admin/products')
  }

  const [validated, setValidated] = useState(false);

  const [imageRemoved, setImageRemoved] = useState(false)
  const [isUploading, setIsUploading] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  }

const onHover = {
  cursor: "pointer",
  position: "absolute",
  left: "5px",
  top: "-10px",
  transform: "scale(2.7)",
};

const imageDeleteHandler = async (imagePath, pageId) => {

  console.log("[imageDeleteHandler] id=",imagePath)
  console.log("[imageDeleteHandler] id=",pageId)

  let encoded = encodeURIComponent(imagePath)
  if (process.env.NODE_ENV !== "production") {
    await axios.delete(`/api/pages/admin/image/${encoded}/${pageId}`);
  } else {
    await axios.delete(`/api/pages/admin/image/${encoded}/${pageId}`);
  }
  // ricarico il tab, ma al momento ricarico con sempre attivo il tab 0
  setValue(value);
  window.location.assign('/admin/configs')
}

const uploadImagesApiRequest = async (images, pageId) => {

  console.log("[uploadImagesApiRequest]")
  console.log("[uploadImagesApiRequest] images=",images)
  console.log("[uploadImagesApiRequest] id=",pageId)

  const formData = new FormData();
  Array.from(images).forEach((image) => {
    formData.append("images", image);
  });
  const { data } = await axios.post("/api/pages/admin/upload?pageId=" + pageId, formData);
  return data;
};

const [product, setProduct] = useState({});

const updatePageApiRequest = async (pageId, formInputs) => {
  const { data } = await axios.put(`/api/pages/admin/${pageId}`, { ...formInputs });
  return data;
}

const updateConfigApiRequest = async (configId, formInputs) => {
  const { data } = await axios.put(`/api/configs/admin/${configId}`, { ...formInputs });
  return data;
}

const getPageByMenuValueApiRequest = async (menuValue) => {
  console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
  const url = `/api/pages/menuValue/`+menuValue;
  const { data } = await axios.get(url);
  return data
}

const readPageByMenuValue = (titleString) => {
  console.log("[readPageByMenuValue} page con menuValue =",titleString);
  getPageByMenuValueApiRequest(titleString).then((data) => { 
    console.log(`data =`, data);  
    setPage(data)
    console.log(`page =`, page);  
    setTitle(data?.title.text);
    setSubTitle(data?.subTitle.text);
    setDescription(data?.description.text);
    setTabAttivo('home');
    setId(data?._id)
    setTitleImg1(data?.imagesTitle[0].text);
    setSubTitleImg1(data?.imagesSubTitle[0].text);
    setTitleImg2(data?.imagesTitle[1].text);
    setSubTitleImg2(data?.imagesSubTitle[1].text);
    setTitleImg3(data?.imagesTitle[2].text);
    setSubTitleImg3(data?.imagesSubTitle[2].text);
  })
  .catch((er) => {
    console.log(er);
  });  
}

const getConfigByProgressivoApiRequest = async (progressivoRagSoc) => {
  console.log("[getConfigByProgressivoApiRequest] leggo const con progressivo =",progressivoRagSoc);
  const url = `/api/configs/progressivo/`+progressivoRagSoc;
  const { data } = await axios.get(url);
  return data
}

const handleSelectStyle=(e)=>{
  setSiteStyleActive(e)
}

// ---------------------  useEffect INI
  
useEffect(() => {
  getPageByMenuValueApiRequest('HOME').then((data) => { 
    //setPages(data)
    setPage(data)
    //console.log(`pages =`, pages);  
    console.log(`page =`, page);  
    setTitle(data?.title.text);
    setSubTitle(data?.subTitle.text);
    setDescription(data?.description.text);
    setTabAttivo('home');
    setId(data?._id);
    setTitleImg1(data?.imagesTitle[0].text);
    setSubTitleImg1(data?.imagesSubTitle[0].text);
    setTitleImg2(data?.imagesTitle[1].text);
    setSubTitleImg2(data?.imagesSubTitle[1].text);
    setTitleImg3(data?.imagesTitle[2].text);
    setSubTitleImg3(data?.imagesSubTitle[2].text);
    //console.log(`pag[0]?.id =`, pag[0]?._id.toString);  
    console.log(`id =`, id);  
    console.log(`page?.title.text =`, page?.title.text);  
    console.log(`page?.description.text =`, page?.description.text);  
    //console.log(`pages[0]?.description.text =`, pages[0]?.description.text);  
  })
  .catch((er) => {
    console.log(er);
  });  
}, [tabAttivo,setPage,setId])


useEffect(() => {
  getConfigByProgressivoApiRequest('0001').then((data) => { 
    console.log(`data =`, data);  
    setIdConfig(data?._id);
    setRagioneSociale(data?.ragioneSociale);
    setCliente(data?.cliente);
    setPartitaIva(data?.partitaIva);
    setIndirizzo(data?.indirizzo);
    setCap(data?.cap);
    setCitta(data?.citta);
    setProvincia(data?.provincia);
    setTelefono(data?.telefono);
    setTelefono1(data?.telefono1);
    setTelefono2(data?.telefono2);
    setTelefono3(data?.telefono3);
    setMobile(data?.mobile);
    setMobile1(data?.mobile1);
    setMobile2(data?.mobile2);
    setMobile3(data?.mobile3);
    setEmail(data?.email);
    setEmail1(data?.email1);
    setEmail2(data?.email2);
    setEmail3(data?.email3);
    setImageLogo(data?.imageLogo);
    setAliquotaIva(data?.aliquotaIva);
    setAliquotaIvaRid(data?.aliquotaIvaRid);
    setAliquotaIvaMin(data?.aliquotaIvaMin);
    setAliquotaIva1(data?.aliquotaIva1);
    setAliquotaIva2(data?.aliquotaIva2);
    setAliquotaIva3(data?.aliquotaIva3);
    setCustomerCode(data?.customerCode);
    setTitleProduct(data?.titleProduct);
    //setSiteStyleActive(data?.siteStyleActive);
    if (data?.siteStyleActive==='A') setSiteStyleActive('Classic');
    if (data?.siteStyleActive==='B') setSiteStyleActive('Jazz');
    if (data?.siteStyleActive==='C') setSiteStyleActive('Modern');
    if (data?.siteStyleActive==='D') setSiteStyleActive('Time');
    setEnableSwitchStyle(data?.enableSwitchStyle);
    setUrlSiteImageServer(data?.urlSiteImageServer);
    setUrlCustomerImageServer(data?.urlCustomerImageServer);
    console.log(`ragioneSociale =`, data?.ragioneSociale);  
    console.log(`urlCustomerImageServer =`, data?.urlCustomerImageServer);  
  })
  .catch((er) => {
    console.log(er);
  });  
}, [ragioneSociale,setEmail,setUrlCustomerImageServer])



  // ---------------------  useEffect FIN
  
  return (

      <Row className="m-5">

        <Col md={2}>
          <AdminLinksComponent />
        </Col>

        <Col md={10}>

          <h1>
            Configura 
          </h1>

          <Box sx={{ bgcolor: 'background.paper', width: 800 }}>

            <AppBar position="static" >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Home" {...a11yProps(0)} />
                <Tab label="Novità" {...a11yProps(1)} />
                <Tab label="Brand" {...a11yProps(2)} />
                <Tab label="Coupon" {...a11yProps(3)} />
                <Tab label="Gift card" {...a11yProps(4)} />
                <Tab label="Chi siamo" {...a11yProps(5)} />
                <Tab label="Contatti" {...a11yProps(6)} />
                <Tab label="Login" {...a11yProps(7)} />
                <Tab label="Account" {...a11yProps(8)} />
                <Tab label="Generale" {...a11yProps(9)} />
              </Tabs>
            </AppBar>

            {/* ------------------------ tab 1 ---------------------- */}
            {/*  modifica HOME          pages[0] di collection pages  */}

            <TabPanel value={value} index={0} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                      borderLeft: "2ch"
                    }}>
                    Descrizione
                  </InputGroup.Text>
                  <Form.Control name="description" defaultValue={description} as="textarea" rows="4" />
                </InputGroup>
  
                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>
              
                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>

                <Row className="justify-content-center mt-5">                 
                  <Col md={2}>
                    <Button 
                        type="button"
                        className="btn btn-warning"
                        onClick={(e) => {gotoIndietro(e); }}
                        style={{
                          width: "80px",
                        }}
                      >
                        Indietro
                    </Button>  
                  </Col>

                  <Col md={2}>
                    <Button variant="primary" type="submit">
                      Salva
                    </Button>
                  </Col>
                </Row>

              </Form>
 
             </TabPanel>
            
            {/* ------------------------ tab 2 ---------------------- */}
            {/*  modifica NOVITA        pages[1] di collection pages  */}

            <TabPanel value={value} index={1} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                      borderLeft: "2ch"
                    }}>
                    Descrizione
                  </InputGroup.Text>
                  <Form.Control name="description" defaultValue={description} as="textarea" rows="4" />
                </InputGroup>
  
                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>
              
                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>

                <Row className="justify-content-center mt-5">                 
                  <Col md={2}>
                    <Button 
                        type="button"
                        className="btn btn-warning"
                        onClick={(e) => {gotoIndietro(e); }}
                        style={{
                          width: "80px",
                        }}
                      >
                        Indietro
                    </Button>  
                  </Col>

                  <Col md={2}>
                    <Button variant="primary" type="submit">
                      Salva
                    </Button>
                  </Col>
                </Row>

              </Form>
 
             </TabPanel>

            {/* ------------------------ tab 3 ---------------------- */}
            {/*  modifica BRAND                pages[2] collec pages  */}

            <TabPanel value={value} index={2} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                      borderLeft: "2ch"
                    }}>
                    Descrizione
                  </InputGroup.Text>
                  <Form.Control name="description" defaultValue={description} as="textarea" rows="4" />
                </InputGroup>
  
                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>
              
                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>

                <Row className="justify-content-center mt-5">                 
                  <Col md={2}>
                    <Button 
                        type="button"
                        className="btn btn-warning"
                        onClick={(e) => {gotoIndietro(e); }}
                        style={{
                          width: "80px",
                        }}
                      >
                        Indietro
                    </Button>  
                  </Col>

                  <Col md={2}>
                    <Button variant="primary" type="submit">
                      Salva
                    </Button>
                  </Col>
                </Row>

              </Form>
 
             </TabPanel>

            {/* ------------------------ tab 4 ---------------------- */}
            {/*  modifica COUPON        pages[3] di collection pages  */}

            <TabPanel value={value} index={3} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                      borderLeft: "2ch"
                    }}>
                    Descrizione
                  </InputGroup.Text>
                  <Form.Control name="description" defaultValue={description} as="textarea" rows="4" />
                </InputGroup>
  
                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>
              
                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>

                <Row className="justify-content-center mt-5">                 
                  <Col md={2}>
                    <Button 
                        type="button"
                        className="btn btn-warning"
                        onClick={(e) => {gotoIndietro(e); }}
                        style={{
                          width: "80px",
                        }}
                      >
                        Indietro
                    </Button>  
                  </Col>

                  <Col md={2}>
                    <Button variant="primary" type="submit">
                      Salva
                    </Button>
                  </Col>
                </Row>

              </Form>
 
             </TabPanel>


            {/* ------------------------ tab 5 ---------------------- */}
            {/*  modifica GIFT CARD     pages[4] di collection pages  */}

            <TabPanel value={value} index={4} dir={theme.direction}>
      
              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                      borderLeft: "2ch"
                    }}>
                    Descrizione
                  </InputGroup.Text>
                  <Form.Control name="description" defaultValue={description} as="textarea" rows="4" />
                </InputGroup>

                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>

                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                      uploadImagesApiRequest(e.target.files, id)
                      .then(data => {
                          setIsUploading("upload files completato");
                          setImageUploaded(!imageUploaded);
                      })
                        .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>

                <Row className="justify-content-center mt-5">
                    <Col md={2}>
                      <Button 
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {gotoIndietro(e); }}
                          style={{
                            width: "80px",
                          }}
                        >
                          Indietro
                      </Button>  
                    </Col>

                    <Col md={2}>
                      <Button variant="primary" type="submit">
                        Salva
                      </Button>
                  </Col>
                </Row>

              </Form>
              
            </TabPanel>
            
            {/* ------------------------ tab 6 ---------------------- */}
            {/*  modifica CHI SIAMO     pages[5] di collection pages  */}

            <TabPanel value={value} index={5} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                      borderLeft: "2ch"
                    }}>
                    Descrizione
                  </InputGroup.Text>
                  <Form.Control name="description" defaultValue={description} as="textarea" rows="4" />
                </InputGroup>

                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>

                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                      uploadImagesApiRequest(e.target.files, id)
                      .then(data => {
                          setIsUploading("upload files completato");
                          setImageUploaded(!imageUploaded);
                      })
                        .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>

                <Row className="justify-content-center mt-5">
                    <Col md={2}>
                      <Button 
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {gotoIndietro(e); }}
                          style={{
                            width: "80px",
                          }}
                        >
                          Indietro
                      </Button>  
                    </Col>

                    <Col md={2}>
                      <Button variant="primary" type="submit">
                        Salva
                      </Button>
                  </Col>
                </Row>

              </Form>

            </TabPanel>

            {/* ------------------------ tab 7 ---------------------- */}
            {/*  modifica CONTATTI      pages[6] di collection pages  */}

            <TabPanel value={value} index={6} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>

                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                      uploadImagesApiRequest(e.target.files, id)
                      .then(data => {
                          setIsUploading("upload files completato");
                          setImageUploaded(!imageUploaded);
                      })
                        .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>
                <Row className="justify-content-center mt-5">
                  
                    <Col md={2}>
                      <Button 
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {gotoIndietro(e); }}
                          style={{
                            width: "80px",
                          }}
                        >
                          Indietro
                      </Button>  
                    </Col>

                    <Col md={2}>
                      <Button variant="primary" type="submit">
                        Salva
                      </Button>
                  </Col>
                </Row>

              </Form>

            </TabPanel>


            {/* ------------------------ tab 8 ---------------------- */}
            {/*  modifica LOGIN         pages[8] di collection pages  */}

            <TabPanel value={value} index={7} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>

                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                      uploadImagesApiRequest(e.target.files, id)
                      .then(data => {
                          setIsUploading("upload files completato");
                          setImageUploaded(!imageUploaded);
                      })
                        .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>
                <Row className="justify-content-center mt-5">
                  
                    <Col md={2}>
                      <Button 
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {gotoIndietro(e); }}
                          style={{
                            width: "80px",
                          }}
                        >
                          Indietro
                      </Button>  
                    </Col>

                    <Col md={2}>
                      <Button variant="primary" type="submit">
                        Salva
                      </Button>
                  </Col>
                </Row>

              </Form>

            </TabPanel>

            {/* ------------------------ tab 9 ---------------------- */}
            {/*  modifica ACCOUNT         pages[8] di collection pages  */}

            <TabPanel value={value} index={8} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Titolo
                  </InputGroup.Text>
                  <Form.Control name="title" defaultValue={title} type="text" />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "110px",
                    }}>
                    Sottotitolo
                  </InputGroup.Text>
                  <Form.Control name="subTitle" defaultValue={subTitle} type="text" />
                </InputGroup>

                <Form.Group controlId="formFileMultiple" className="mb-3 mt-3"
                                style={{
                                  width: "75ch", marginLeft:"3ch", color: "blue"
                                }}>

                  <Form.Label>Immagini</Form.Label>
                  <Row>
                    {page?.images &&
                      page?.images.map((image, idx) => (
                        <Col key={idx} style={{ position: "relative" }} xs={3}>
                          <Image
                            crossOrigin="anonymous"
                            src={image.path ?? null}
                            fluid
                          />
                          <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                        </Col>
                      ))}
                  </Row>

                  <Form.Control  type="file" multiple onChange={e => {
                    setIsUploading("upload files in corso ..."); 
                    if (process.env.NODE_ENV !== "production") {
                        uploadImagesApiRequest(e.target.files, id)
                        .then(data => {
                            setIsUploading("upload files completato");
                            setImageUploaded(!imageUploaded);
                        })
                          .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    } else {
                      uploadImagesApiRequest(e.target.files, id)
                      .then(data => {
                          setIsUploading("upload files completato");
                          setImageUploaded(!imageUploaded);
                      })
                        .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                    }
                  }} />

                  {isUploading}

                </Form.Group>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 1
                  </InputGroup.Text>
                  <Form.Control name="titleImg1" defaultValue={titleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 1
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg1" defaultValue={subTitleImg1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 2
                  </InputGroup.Text>
                  <Form.Control name="titleImg2" defaultValue={titleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 2
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg2" defaultValue={subTitleImg2} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Titolo img 3
                  </InputGroup.Text>
                  <Form.Control name="titleImg3" defaultValue={titleImg3} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Sottotitolo img 3
                  </InputGroup.Text>
                  <Form.Control name="subTitleImg3" defaultValue={subTitleImg3} type="text" />
                </InputGroup>
                <Row className="justify-content-center mt-5">
                  
                    <Col md={2}>
                      <Button 
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {gotoIndietro(e); }}
                          style={{
                            width: "80px",
                          }}
                        >
                          Indietro
                      </Button>  
                    </Col>

                    <Col md={2}>
                      <Button variant="primary" type="submit">
                        Salva
                      </Button>
                  </Col>
                </Row>

              </Form>

            </TabPanel>


            {/* ----------------------- tab 10 ---------------------- */}
            {/*  modifica GENERALE      pages[9] di collection pages  */}

            <TabPanel value={value} index={9} dir={theme.direction}>

              <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                      style={{
                        width: "21ch", justifyContent: "right"
                      }}>
                      Stile del sito
                  </InputGroup.Text>
                  <DropdownButton
                    alignRight
                    title={siteStyleActive}
                    id="dropdown-menu-align-right"
                    onSelect={handleSelectStyle} 
                    variant="warning"
                  >
                    <Dropdown.Item eventKey="Classic">Classic</Dropdown.Item>
                    <Dropdown.Item eventKey="Jazz">Jazz</Dropdown.Item>
                    <Dropdown.Item eventKey="Modern">Modern</Dropdown.Item>
                    <Dropdown.Item eventKey="Time">Time</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>

                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Ragione sociale
                  </InputGroup.Text>
                  <Form.Control name="ragioneSociale" defaultValue={ragioneSociale} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Cliente
                  </InputGroup.Text>
                  <Form.Control name="cliente" defaultValue={cliente} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Partita iva
                  </InputGroup.Text>
                  <Form.Control name="partitaIva" defaultValue={partitaIva} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Indirizzo
                  </InputGroup.Text>
                  <Form.Control name="indirizzo" defaultValue={indirizzo} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Cap
                  </InputGroup.Text>
                  <Form.Control name="cap" defaultValue={cap} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Città 
                  </InputGroup.Text>
                  <Form.Control name="citta" defaultValue={citta} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Provincia
                  </InputGroup.Text>
                  <Form.Control name="provincia" defaultValue={provincia} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Telefono
                  </InputGroup.Text>
                  <Form.Control name="telefono" defaultValue={telefono} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Telefono 1
                  </InputGroup.Text>
                  <Form.Control name="telefono1" defaultValue={telefono1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Mobile
                  </InputGroup.Text>
                  <Form.Control name="mobile" defaultValue={mobile} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Mobile 1
                  </InputGroup.Text>
                  <Form.Control name="mobile1" defaultValue={mobile1} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Email
                  </InputGroup.Text>
                  <Form.Control name="email" defaultValue={email} type="text" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Email 1
                  </InputGroup.Text>
                  <Form.Control name="email1" defaultValue={email1} type="text" />
                </InputGroup>


                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Aliquota Iva
                  </InputGroup.Text>
                  <Form.Control name="aliquotaIva" defaultValue={aliquotaIva} type="number" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Aliquota Iva ridotta
                  </InputGroup.Text>
                  <Form.Control name="aliquotaIvaRid" defaultValue={aliquotaIvaRid} type="number" />
                </InputGroup>
                <InputGroup className="border-0">
                  <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "21ch", justifyContent: "right"
                    }}>
                    Aliquota Iva minima
                  </InputGroup.Text>
                  <Form.Control name="aliquotaIvaMin" defaultValue={aliquotaIvaMin} type="number" />
                </InputGroup>

                <Row className="justify-content-center mt-5">
                  
                    <Col md={2}>
                      <Button 
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {gotoIndietro(e); }}
                          style={{
                            width: "80px",
                          }}
                        >
                          Indietro
                      </Button>  
                    </Col>

                    <Col md={2}>
                      <Button variant="primary" type="submit">
                        Salva
                      </Button>
                  </Col>
                </Row>

              </Form>

            </TabPanel>

          </Box>

      </Col>

      </Row>

  );
};

export default ConfigsPageComponent;
