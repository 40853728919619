import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { useState, useEffect } from "react";
import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Redirect, Link } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AdminCategoriesPageComponent = ({ fetchCategories, deleteCategory }) => {
 
  const [categories, setCategories] = useState([]);
  const [categoryDeleted, setCategoryDeleted] = useState(false); 
  const dispatch = useDispatch();

  const [finalClickInfo, setFinalClickInfo] = useState(null);

  // --- INI -------  Confirm Dialog
  const [idCategoryInWorking, setIdCategoryInWorking] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const PopupConfirmConfirm = async() => {
    console.log('[PopupConfirmConfirm]')
    const data = await deleteCategory(idCategoryInWorking)
    if (data.message === "category removed") {
        setCategoryDeleted(!categoryDeleted);
    }
    setOpen(false);
    window.location.assign('/admin/categories')
  };

  const PopupConfirmClose = () => {
    console.log('[PopupConfirmClose]')
    setOpen(false);
  };
  
  const ViewConfirmDialog = () => {
    return (
      <Dialog
      open={open}
      onClose={PopupConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle 
          id="alert-dialog-title"
          style={{background: "#4bf5cc", fontWeight:"bold"}}>
          {"Cancellazione categoria"}
      </DialogTitle>

      <DialogContent>
          <DialogContentText id="alert-dialog-description" >
              &nbsp;
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" >
            Confermi la cancellazione della categoria selezionata? 
           </DialogContentText>
           <DialogContentText id="alert-dialog-description" >
              &nbsp;
          </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button 
          className="btn btn-warning"
          onClick={PopupConfirmClose}>
            Annulla
        </Button>
        <Button
          className="btn btn-primary" 
          onClick={PopupConfirmConfirm}
          autoFocus
        >
          Conferma
        </Button>
      </DialogActions>

    </Dialog>
    );
  };
  // --- FIN -------  Confirm Dialog

  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
    console.log('onclick params=',params)
    console.log('onclick params riga =',params.id)
    console.log('onclick params titoloColonna =',params.field)
  };
  
  const paginationModel = { page: 0, pageSize: 5 };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{ tooltip: { title: 'visualizza colonne' } }}
        />
        <GridToolbarFilterButton
          slotProps={{ tooltip: { title: 'Imposta filtri' } }}
        />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Cambia densità' } }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Esporta dati' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'name', headerName: 'Nome', width: 300, headerAlign: 'center' },
    { field: 'description', headerName: 'Descrizione', width: 300, headerAlign: 'center'},
    { field: 'category', headerName: 'Categoria', width: 150, headerAlign: 'center', aligh:"center"},
    { field: 'color', headerName: 'Colore', width: 150, headerAlign: 'center', aligh:"center"},
    {
      field: "actions",
      type: "actions",
      headerName: 'Azioni',
      hide: true,
      width: 100,
      getActions: (params: any) => [
        <GridActionsCellItem 
          icon={<EditIcon />} 
          label="Edit" 
          component={Link}
          to={`/admin/edit-category/${params.id}`}
        />,
        <GridActionsCellItem 
          icon={<DeleteIcon />} 
          label="Delete" 
          onClick={() => {
            setIdCategoryInWorking(params.id)
            handleClickOpen();
          }}
        />
      ]
    },
    { field: 'image', headerName: 'Immagine', width: 400, headerAlign: 'center' },
  ];


  useEffect(() => {
    const abctrl = new AbortController();
    fetchCategories(abctrl)
      .then((res) => setCategories(res))
      .catch((er) =>
      dispatch(logout())
      );
    return () => abctrl.abort();
  }, [categoryDeleted]);

  return (
   
    <Row className="m-5">
      
      <Col md={2}>
        <AdminLinksComponent />
      </Col>

      <Col md={10}>
        <h1>
          Categorie {" "} 
          <LinkContainer to="/admin/create-new-category">
            <Button variant="primary" >
              Nuova Categoria
            </Button>
          </LinkContainer>
        </h1>

        <Paper sx={{ height: 430, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row._id}
            rows={categories}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10, 15, 20]}
            slots={{
              toolbar: CustomToolbar,
            }} 
            slotProps={{
              pagination: {
                labelRowsPerPage: "righe:",               
              },
            }}
            autoHeight={true}
            onCellClick={handleOnCellClick}
            sx={{
                  '& .MuiDataGrid-columnHeader': 
                  {
                    backgroundColor: "cyan",
                    color: "black",
                    border: 2,
                    boxShadow: 1,
                    fontWeight: 700,                           },
                  '& .MuiDataGrid-cell': 
                  {
                    backgroundColor: "lightcyan",
                    color: "gray",
                    border: 1,
                    boxShadow: 1,
                    fontWeight: 300,
                  },
                  '& .MuiDataGrid-cell:hover':
                  {
                    backgroundColor: "#b0c0e5",
                    color: 'primary.main',              
                  },     
                  '&.Mui-selected':                          
                  {
                    color: 'primary.main',              
                  }     

            }} />

        </Paper>

        < ViewConfirmDialog />

        </Col>
    </Row>
  );
};

export default AdminCategoriesPageComponent;
