// ---------------------------------------------
// ---              FE constants             ---
// ---------------------------------------------


// impostazione stile SITO e abilitazione comboBox x cambiarlo
let SITE_STYLE_ACTIVE="C"

// se TRUE appare il popup STILE con le voci degli stiliSito attivabili
//const ENABLE_SWITCH_STILE = true;
const ENABLE_SWITCH_STILE = false;

// se TRUE il visitatore poò creare il suo account
const ENABLE_CREATE_ACCOUNT = true;
//const ENABLE_CREATE_ACCOUNT = false;

const NUMBER_IMAGE_UPDATING_CATEGORY = 3;
const NUMBER_IMAGE_UPDATING_PRODUCT = 3;

// identificazione cliente
const CUSTOMER = "Abaco srl";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "via Roma 10";
const CUSTOMER_CITTA = "20121 Milano";
const CUSTOMER_EMAIL = "informazioni@studio-professionale.it";

const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-abbigliamentodonna.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-accessoridonna.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-attrezzigiardino.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-computers.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-games.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-lavatrici.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-trapani.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-tablets.jpg",
];
               
// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/myplanner/storage-images/site-myshoponline-it
const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/myshop/storage-images/customer-"+CUSTOMER_CODE+"/";

module.exports = { 
  ENABLE_SWITCH_STILE,
  ENABLE_CREATE_ACCOUNT,
  SITE_STYLE_ACTIVE,
  NUMBER_IMAGE_UPDATING_CATEGORY, 
  NUMBER_IMAGE_UPDATING_PRODUCT,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  CUSTOMER_SLIDE_URL_ARRAY,
    
  URL_IMAGES_CUSTOMER,
};
